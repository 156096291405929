import React, {forwardRef} from "react";
import {createPortal} from 'react-dom';
import useSidebarLogic from "./Sidebar.logic";
import {animated} from "@react-spring/web";
import OutsideClickHandler from "react-outside-click-handler";

/**
 * @param children
 * @param title
 * @param isOpen
 * @returns {|null}
 * @constructor
 */
const RightSidebar = forwardRef(({ children, isOpen = false, closeSidebar}, ref ) => {
  if (!isOpen) {
    return null
  }

  const { transition, onCloseSidebar, backgroundTransition }  = useSidebarLogic(closeSidebar, ref, 'right');

  return createPortal(
    <div className="sidebar z-10 overflow-y-scroll top-0 left-0 fixed w-full z-50">
      <animated.div style={{ zIndex: 1, ...backgroundTransition }}>
        <div className="modal-background absolute top-0 right-0 w-full h-full bg-gray-800 opacity-50" />
      </animated.div>

      <animated.div style={{ overflowY: 'auto', zIndex: 10, ...transition }}>
        <div className="sidebar-menu relative float-right max-w-screen-xl min-h-screen bg-white min-w-[20rem] right-0 sm:max-w-[30rem] max-w-[85vw]">
          <OutsideClickHandler onOutsideClick={onCloseSidebar}>
            {children}
          </OutsideClickHandler>
        </div>
      </animated.div>,
    </div>,
    document.getElementById('__next'))
});

export default RightSidebar;
