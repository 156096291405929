import {CART_FIELDS} from "./fragments/CartFields";
import transformCart from "../graphql-transformers/transform-cart";

/**
 *
 * @returns {Promise<any>}
 */
export async function getOpenCart(axiosInstance, { cartId }) {
  const QUERY = `
      #graphql
      ${CART_FIELDS}
      query cartQuery($cartId: ID!) {
          cart(id: $cartId) {
              ...CartFields
          }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      cartId: cartId,
    }
  }).catch(e => { console.log(e); return null});

  if (response.data?.data.cart) {
    return transformCart(response.data.data.cart)
  } else {
    return null;
  }
}

export async function addToCart(axiosInstance, { cartId, variantId }) {
  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: `mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
           cart {
            id
            createdAt
            updatedAt
            totalQuantity
            lines(first:10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      selectedOptions {
                        name
                        value
                      }
                      image {
                         originalSrc
                         transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      product {
                        id
                        title
                        handle
                      }
                    }
                  }
                }
              }
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }`,
    variables: {
      cartId: cartId,
      lines: {
        quantity: 1,
        merchandiseId: variantId,
      },
    },
  }).catch(e => { console.log(e); return null});

  if (response.data?.data?.cartLinesAdd) {
    return transformCart(response.data.data.cartLinesAdd.cart);
  } else {
    return response.data;
  }

}
/**
 *
 * @returns {Promise<any>}
 */
export async function removeCartLine(axiosInstance, {cartId, cartLineId }) {
  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
           cart {
            id
            createdAt
            updatedAt
            totalQuantity
            lines(first:10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      selectedOptions {
                        name
                        value
                      }
                      image {
                         originalSrc
                         transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      product {
                        id
                        title
                        handle
                      }
                    }
                  }
                }
              }
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }`,
    variables: {
      cartId: cartId,
      lineIds: [cartLineId],
    },
  }).catch(e => { console.log(e); return null});

  if (response.data?.data?.cartLinesRemove) {
    return transformCart(response.data.data.cartLinesRemove.cart);
  } else {
    return null;
  }
}

/**
 *
 * @returns {Promise<any>}
 */
export async function updateCartLine(axiosInstance, { cartId, variantId }) {
  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
           cart {
            id
            createdAt
            updatedAt
            totalQuantity
            lines(first:10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      image {
                         originalSrc
                         transformedSrc
                      }
                      priceV2 {
                        amount
                        currencyCode
                      }
                      product {
                        id
                        title
                        handle
                      }
                    }
                  }
                }
              }
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }`,
    variables: {
      cartId: cartId,
      lines: {
        quantity: quantity,
        merchandiseId: variantId,
        id: cartLineId,
      },
    },
  }).catch(e => { console.log(e); return null});

  if (response.data?.data?.cartLinesUpdate) {
    return transformCart(response.data.data.cartLinesUpdate.cart);
  } else {
    return null;
  }
}

/**
 * @returns {Promise<any>}
 * @param merchandiseId
 * @param axiosInstance
 */
export async function createCart(axiosInstance, { variantId }) {
  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: `mutation createCart($cartInput: CartInput) {
          cartCreate(input: $cartInput) {
             cart {
              id
              createdAt
              updatedAt
              totalQuantity
              lines(first:10) {
                edges {
                  node {
                    id
                    quantity
                    merchandise {
                      ... on ProductVariant {
                        id
                        title
                        selectedOptions {
                          name
                          value
                        }
                        image {
                           originalSrc
                           transformedSrc
                        }
                        priceV2 {
                          amount
                          currencyCode
                        }
                        product {
                          id
                          title
                          handle
                        }
                      }
                    }
                  }
                }
              }
              estimatedCost {
                totalAmount {
                  amount
                  currencyCode
                }
                subtotalAmount {
                  amount
                  currencyCode
                }
                totalTaxAmount {
                  amount
                  currencyCode
                }
                totalDutyAmount {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      `,
    variables: {
      cartInput: {
        lines: [
          {
            quantity: 1,
            merchandiseId: variantId,
          },
        ],
      },
    },
  });

  if (response.data?.data?.cartCreate?.checkoutErrors) {
    throw new Error(response.data?.data?.cartCreate?.checkoutErrors);
  }

  if (response.data?.data?.cartCreate) {
    return transformCart(response.data.data.cartCreate.cart);
  } else {
    return null;
  }
}


// /**
//  * @param checkoutId
//  * @param checkoutContext
//  * @returns {Promise<null|*>}
//  */
// export async function updateCheckoutEmail(checkoutId, checkoutContext) {
//   const response = await axiosInstance.post('/api/2022-07/graphql.json', {
//     query: `mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
//         checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
//           checkout {
//             id
//             email
//             ready
//             webUrl
//             order {
//               id
//               orderNumber
//             }
//             lineItems(first: 5) {
//              edges {
//                node {
//                  title
//                  quantity
//                }
//              }
//            }
//             shippingAddress {
//               id
//               address1
//               city
//               country
//               firstName
//               lastName
//               zip
//             }
//             paymentDueV2 {
//               amount
//               currencyCode
//             }
//             totalPriceV2 {
//               amount
//               currencyCode
//             }
//           }
//           checkoutUserErrors {
//             field
//             message
//           }
//         }
//       }`,
//     variables: {
//       checkoutId: checkoutId,
//       email: checkoutContext.email,
//     }
//   }).catch(e => { console.log(e); return null});
// //
//   if (!isEmpty(response.data?.data?.checkoutEmailUpdateV2?.checkoutUserErrors)) {
//     throw new ValidationError(response.data?.data?.checkoutEmailUpdateV2?.checkoutUserErrors);
//   }
//
//   if (response.data?.data?.checkoutEmailUpdateV2) {
//     return transformCart(response.data.data.checkoutEmailUpdateV2.checkout);
//   } else {
//     return null;
//   }
// }

// /**
//  *
//  * @param paymentToken
//  * @param checkout
//  * @returns {Promise<any>}
//  */
// export async function completeCheckout(paymentToken, checkout) {
//   const billingAddress = {...checkout.shippingAddress};
//
//   delete billingAddress.id;
//
//   const response = await axiosInstance.post('/api/2022-07/graphql.json', {
//     query: `mutation checkoutCompleteWithTokenizedPaymentV3($checkoutId: ID!, $payment: TokenizedPaymentInputV3!) {
//           checkoutCompleteWithTokenizedPaymentV3(checkoutId: $checkoutId, payment: $payment) {
//             checkout {
//               id
//               email
//               ready
//               webUrl
//               order {
//                 id
//                 orderNumber
//               }
//               lineItems(first: 5) {
//                edges {
//                  node {
//                    title
//                    quantity
//                  }
//                }
//              }
//               shippingAddress {
//                 id
//                 address1
//                 city
//                 country
//                 firstName
//                 lastName
//                 zip
//               }
//               paymentDueV2 {
//                 amount
//                 currencyCode
//               }
//               totalPriceV2 {
//                 amount
//                 currencyCode
//               }
//             }
//             checkoutUserErrors {
//               code
//               field
//               message
//             }
//             payment {
//               id
//             }
//           }
//         }
//     `,
//     variables: {
//       checkoutId: checkout.id,
//       payment: {
//         "paymentAmount": checkout.paymentDueV2,
//         "idempotencyKey": paymentToken,
//         "billingAddress": billingAddress,
//         "type": "SHOPIFY_PAY",
//         "paymentData": paymentToken,
//         "test": true,
//       }
//     }
//   }).catch(e => { console.log(e); return null});
//
//   console.log('rrrr', response);
//   return response.data;
// }
//
// export async function getCheckoutById(checkoutId) {
//   const response = await axiosInstance.post('/api/2022-07/graphql.json', {
//     query: `query getCheckout($checkoutId: ID!) {
//          node(id:$checkoutId) {
//           ... on Checkout {
//             id
//             email
//             ready
//             webUrl
//             email
//             order {
//               id
//               orderNumber
//               statusUrl
//               currencyCode
//               cancelReason
//               canceledAt
//               financialStatus
//               currentTotalPrice {
//                 amount
//                 currencyCode
//               }
//             }
//             lineItems(first: 5) {
//              edges {
//                node {
//                  title
//                  quantity
//                  variant {
//                     ... on ProductVariant {
//                       id
//                       title
//                       image {
//                          originalSrc
//                          transformedSrc
//                       }
//                       priceV2 {
//                         amount
//                         currencyCode
//                       }
//                       product {
//                         id
//                         title
//                         handle
//                       }
//                     }
//                   }
//                }
//              }
//            }
//             shippingAddress {
//               id
//               address1
//               city
//               country
//               firstName
//               lastName
//               zip
//               phone
//             }
//             availableShippingRates {
//               ready
//               shippingRates {
//                 handle
//                 priceV2 {
//                   amount
//                   currencyCode
//                 }
//                 title
//               }
//             }
//             paymentDueV2 {
//               amount
//               currencyCode
//             }
//             totalPriceV2 {
//               amount
//               currencyCode
//             }
//           }
//         }
//     }`,
//     variables: {
//       checkoutId: checkoutId
//     }
//   }).catch(e => { console.log(e); return null});
//
//   if (response.data?.data?.node) {
//     return transformCheckout(response.data.data.node);
//   } else {
//     return null;
//   }
//
// }
//
//
// export async function updateCheckoutItemsReplace(checkoutId, cartLines) {
//   const lineItems = cartLines.map((cartLine) => {
//     return {
//       quantity: cartLine.quantity,
//       variantId: cartLine.merchandise.id,
//     }
//   })
//
//   const response = await axiosInstance.post('/api/2022-07/graphql.json', {
//     query: `mutation checkoutLineItemsReplace($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
//       checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
//           checkout {
//             id
//             email
//             ready
//             webUrl
//             order {
//               id
//               orderNumber
//             }
//             lineItems(first: 5) {
//              edges {
//                node {
//                  title
//                  quantity
//                }
//              }
//            }
//             shippingAddress {
//               id
//               address1
//               city
//               country
//               firstName
//               lastName
//               zip
//             }
//             paymentDueV2 {
//               amount
//               currencyCode
//             }
//             totalPriceV2 {
//               amount
//               currencyCode
//             }
//           }
//           userErrors {
//             field
//             message
//           }
//         }
//       }`,
//     variables: {
//       checkoutId: checkoutId,
//       lineItems: lineItems,
//     }
//   }).catch(e => { console.log(e); return null});
//
//   if (!isEmpty(response.data?.data?.checkoutLineItemsReplace?.userErrors)) {
//     throw new ValidationError(response.data?.data?.checkoutLineItemsReplace?.userErrors);
//   }
//
//   if (!isEmpty(response.data?.errors)) {
//     throw new ValidationError(response.data?.errors);
//   }
//
//   if (response.data?.data?.checkoutLineItemsReplace) {
//     return transformCart(response.data.data.checkoutLineItemsReplace.checkout);
//   } else {
//     return null;
//   }
// }
//
