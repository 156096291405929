export const PRODUCT_IMAGE_FIELDS = `
    #graphql
    fragment ProductImageFields on Image {
        id
        originalSrc
        transformedSrc
        width
        height
    }
`
