import {unpackEdges} from "../functions";

/**
 *
 * @returns {*|null}
 * @param cart
 */
export default function transformCart(cart) {
  cart.lines = unpackEdges(cart, 'lines');

  return cart;
}
