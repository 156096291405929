import axios from 'axios';

import {useStateMachine} from "little-state-machine";
import {setCart, setInitialized, setUser} from "../../lib/actions";
import * as shopifyClientApi from "../../lib/api-client-side-shopify";
import * as api from "../../lib/api-client-side-shopify";
import {useContext, useEffect} from "react";
import {getOpenCart} from "../../lib/resources/cart";
import LocalCache from "../../lib/LocalCache";
import RecentlyViewed from "../../lib/RecentlyViewedList";
import AppContext from "../../lib/AppContext";
import axiosStorefrontInstance from "../../lib/axios-instances/axios-storefront-instance";

/**
 *
 * @returns {Promise<any>}
 */
export async function createGuestSession() {
  const response = await axios.get('/api/public-session');

  return response.data;
}

const loadSession = () => {
  const token = localStorage.getItem('token')

  if (!token) {
    return createGuestSession().then((data) => {
      localStorage.setItem('token', data.token)
    })
  }
};

const loadUser = (actions) => {
  const token = localStorage.getItem('customerAccessToken')

  if (token) {
    try {
      shopifyClientApi.findCustomer(token).then((data) => {
        actions.setUser({user: data})
      })
    } catch (e) {
      localStorage.removeItem('customerAccessToken');
    }
  }
}

export const loadCart = (actions, state) => {
  if (localStorage.getItem('cartId')) {
    return getOpenCart(axiosStorefrontInstance, {cartId: localStorage.getItem('cartId')}).then((cart) => {
      actions.setCart({cart: cart});
    }).catch((error) => {
      localStorage.removeItem('cartId');
      actions.setCart({cart: null});
    });
  } else {
    // Make sure it's empty if no cartId is present
    if (state.cart) {
      actions.setCart({cart: null});
    }
  }
}

export const loadShop = (actions) => {
  return api.getShop().then((shop ) => {
    actions.setCart({shop: shop});
  }).catch((e) => {
    actions.setCart({shop: null});
  });
}

export const loadRecentlyViewed = () => {
  const list = LocalCache.get('recently_viewed');

  RecentlyViewed.setList(list || []);

}

export default function useInitialize() {
  const [ appContext, setContext ] = useContext(AppContext);


  const { actions, state } = useStateMachine({
    setUser, setCart, setInitialized
  });

  useEffect(() => {
    if (!appContext.initialized) {
      const init = async () => {
        await loadSession();
        await loadUser(actions);
        await loadCart(actions, state);
        await loadShop(actions);
        loadRecentlyViewed();
      }

      init({actions}).finally(() => {
        setContext({...appContext, initialized: true});
      })
    }
  }, [appContext.initialized]);
}
