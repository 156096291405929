export const MENU_FIELDS = `
    #graphql
    fragment MenuFields on Menu {
        id
        items {
            id
            title
            url
            items {
                id
                title
                url
            }
        }
    }
`
