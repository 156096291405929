import {RiArrowDownSLine, RiArrowUpSLine} from "react-icons/ri";
import {useEffect, useRef, useState} from "react";
import {BsDot} from "react-icons/bs";
import NavLink from "../nav-link";
import {classNames} from "../../lib/functions";
import {useRouter} from "next/router";
import tw from "tailwind-styled-components";

const MenuItems = tw.ul`
  content
  px-5
  eas-in-out
  duration-500 
  transition-all
  transition-max-height 
  submenu
`;

const MainItem = ({ children, menuItem, isOpen }) => {
  return (
    <div>
      <div className="flex items-center w-full justify-between">
        <div className="flex w-full items-center">
          <div className="w-full gap-3 flex items-center">
            <BsDot/>{menuItem.title}
          </div>
          <span className="w-full flex justify-end">
            <div className={classNames(isOpen ? "flip": "",  "transition-all duration-500")}>
             <RiArrowDownSLine size={30} />
            </div>
          </span>
        </div>
      </div>
      <div className="divider divider-horizontal" />
      <MenuItems className={classNames(isOpen ? 'py-2 transition-opacity max-h-screen': 'opacity-0 p-0 max-h-[0]')}>
          { children }
      </MenuItems>
    </div>
);
}

const ChildItems = ({ menuItem, router }) => {
  return (
    <>
      {menuItem.items.map((menuItem, index) => (
        <li className={classNames(menuItem.pathname === router.asPath ? "bordered bg-lento-almond-light text-gray-800" : "", "")}
            key={'li-sub' + index}>
          <NavLink
            key={index}
            href={menuItem.pathname}
            text={menuItem.title}
            // icon={menuItem.icon}
          />
        </li>
      ))}
    </>
  );
};

export default function CollapseMenuItem({ menuItem, title, subtitle, icon }) {
  const router = useRouter();

  const shouldBeOpen = menuItem.items.filter((menuItem) => {
    return menuItem.pathname === router.asPath;
  });

  const [isOpen, setIsOpen] = useState(shouldBeOpen.length > 0);

  return (
    <div onClick={() => setIsOpen(!isOpen)} className={"w-full menu collapse-item cursor-pointer transition-border"}>
      <MainItem isOpen={isOpen} menuItem={menuItem} >
        <ChildItems router={router} isOpen={isOpen} menuItem={menuItem} />
      </MainItem>
    </div>
  );
}

const ease = (v, pow= 4) => { return 1 - Math.pow(1 - v, pow); }

const createKeyframeAnimation = ({ item, menu, nFrames = 100}) => {
  // Figure out the size of the element when collapsed.
  let {x, y} = calculateCollapsedScale({item, menu});
  let animation = '';
  let inverseAnimation = '';

  for (let step = 0; step <= nFrames; step++) {
    // Remap the step value to an eased one.
    let easedStep = ease(step / nFrames);

    // Calculate the scale of the element.
    const xScale = x + (1 - x) * easedStep;
    const yScale = y + (1 - y) * easedStep;

    animation += `${step}% {
        transform: scale(${xScale}, ${yScale});
    }`;

    // And now the inverse for the contents.
    const invXScale = 1 / xScale;
    const invYScale = 1 / yScale;
    inverseAnimation += `${step}% {
        transform: scale(${invXScale}, ${invYScale});
    }`;
  }

  return `
    @keyframes menuAnimation {
    ${animation}
    }

    @keyframes menuContentsAnimation {
    ${inverseAnimation}
    }`;
}

const calculateCollapsedScale = ({item, menu}) => {
  // The menu title can act as the marker for the collapsed state.
  const collapsed = item.getBoundingClientRect();

  // Whereas the menu as a whole (title plus items) can act as
  // a proxy for the expanded state.
  const expanded = menu.getBoundingClientRect();

  return {
    x: collapsed.width / expanded.width,
    y: collapsed.height / expanded.height
  };
}

  // {/*  { icon && icon }*/}
  // {/*  <span className="font-bold">{title}</span>*/}
  // {/*  <span className="w-full flex justify-end">*/}
  // {/*  { subtitle && subtitle }*/}
  // {/*  </span>*/}
  // {/*</div>*/}
  // {/*<div className="flex">*/}
  // {/*  {!isOpen &&*/}
  // {/*  <RiArrowDownSLine size={20}/>*/}
  // {/*  }*/}
  // {/*  {isOpen &&*/}
  // {/*  <RiArrowUpSLine size={20}/>*/}
  // {/*  }*/}
