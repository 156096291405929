import {useRouter} from "next/router";
import {useEffect} from "react";

export default function useRouterScroll() {
  const router = useRouter();
  const { asPath } = router;

  useEffect(() => {
    setTimeout(() => {
      const y = sessionStorage.getItem( asPath + ':scroll') || 0;

      setTimeout(() => {
        window.scrollTo(0, parseInt(y));

        sessionStorage.removeItem( asPath + ':scroll');
      })
    })
  }, [])
}