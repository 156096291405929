import Link from 'next/link'

export default function NavLink({ children, href, text, icon, className = "" }) {
  return (
    <>
      <Link href={`${href}`}>
        <div className="flex items-center">
          { icon }
          <a className={className}>
            {text}
          </a>
        </div>
      </Link>
      { children }
    </>
  )
}
