/**
 *
 * @param classes
 * @returns {string}
 */
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const jwtDecode = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const parsedHtml = (content) => {
  return {__html: `${content}`};
}

/**
 * @param data
 * @param type
 * @returns {*[]}
 */
export const unpackEdges = (data, type) => {
  if (!data[type]) {
    return [];
  }

  return data[type].edges.map((nodeList) => {
    return {
      ...nodeList.node,
      cursor: nodeList.cursor || null,
      pageInfo: data[type]?.pageInfo || null,
    }

    // const node = nodeList.node;
    // node.cursor = nodeList.cursor || "";
    //
    // return node;
  });
};

export const unpack = (data, type) => {
  if (!data?.data[type]) {
    return null;
  }

  return data.data[type];
};

export function getProperty( propertyName, object ) {
  const parts = propertyName.split( "." );
  const length = parts.length;

  let property = object;

  if (!property) {
    return null;
  }

  for (let i = 0; i < length; i++ ) {
    if (!property) {
      return null;
    }

    property = property.hasOwnProperty(parts[i]) ? property[parts[i]]: null;
  }

  return property;
}

/**
 * @param fieldObject
 * @param data
 * @returns {{required: boolean}}
 */
export const registerOptions = (fieldObject, data = null) => {
  const resultOptions = {
    required: fieldObject.required ?? false,
  }

  if (data && (getProperty(fieldObject.field, data) ?? null)) {
    resultOptions.value = getProperty(fieldObject.field, data)
  } else if (fieldObject.default ?? null) {
    resultOptions.value = fieldObject.default;
  }

  return resultOptions;
};

export const setErrorsFromError = (validationError, setError) => {
  console.log(validationError)
  validationError.data.forEach((error) => {
    const {field, message} = error;
    setError(field[field.length - 1], {
      type: "manual",
      message: message,
    });
  });
}


import { builder } from '@builder.io/react';

export const getBuilderStaticPaths = async (modelName) => {
  const results = await builder.getAll(modelName, {
    key: 'pages:all',
    fields: 'data.url',
    limit: 200,
    options: {
      noTargeting: true,
    },
  });

  const paths = results
    .filter((item) => !item.data?.url?.startsWith('/c/'))
    .filter((item) => item.data?.url !== '/')
    .map((item) => ({
      params: { page: (item.data?.url?.replace('/', '') || '_').split('/') },
    }));

  return {
    paths,
    fallback: true,
  };
};
