import {useStateMachine} from "little-state-machine";
import {FiMinus, FiPlus} from "react-icons/fi";
import Link from "next/link";
import Image from "next/image";
import React, {useImperativeHandle, useRef, useState} from "react";
import {RiLoader4Fill} from "react-icons/ri";
import useCartLogic from "./ShoppingCart.logic";
import {AiOutlineClose, AiOutlineShoppingCart} from "react-icons/ai";
import {currSymbol} from "../../lib/currencies";
import RightSidebar from "../common/RightSidebar";
import {useEffect, forwardRef} from "react";

const ShoppingCart = forwardRef(({}, ref ) => {
  const { state: { cart } } = useStateMachine();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const SidebarRef = useRef();

  useImperativeHandle(ref, () => ({
    openCart() {
      setSidebarOpen(true);
    }
  }));

  const toggleSidebar = () => {
    if (!sidebarOpen) {
      setSidebarOpen(true);
    } else {
      SidebarRef.current.closeSidebar()
    }
  }

  return (
    <>
      <button onClick={toggleSidebar} className="btn btn-ghost btn-circle">
        <div className="indicator">
          { cart  && <span className="badge badge-xs indicator-item">{cart.totalQuantity}</span> }
          <AiOutlineShoppingCart size={25} />
        </div>
      </button>
      <RightSidebar ref={SidebarRef} isOpen={sidebarOpen} closeSidebar={() => setSidebarOpen(false)} >
        <div className="flex justify-end items-center px-3 py-2">
          <button onClick={toggleSidebar} className="btn btn-outline border-0">
            <AiOutlineClose size={25} />
          </button>
        </div>
        <div className="px-4">
          <div className="">
            { !cart || (cart && cart.totalQuantity === 0) && <EmptyCart /> }
            { cart && cart.totalQuantity > 0 && <CartWithItems cart={cart} /> }
          </div>
          {cart &&
          <div className="pb-[4rem]">
            <div className="card-actions py-3 mt-4">
              <Link href="/cart">
                <button className="btn btn-block">View cart</button>
              </Link>
            </div>
            <div className="flex justify-between pb-5">
              <span className="font-bold text-xl">{cart.totalQuantity} Item(s)</span>
              <span className="text-xl">Subtotal: <span className="font-bold">{currSymbol(cart.estimatedCost.totalAmount.currencyCode)}{cart.estimatedCost.totalAmount.amount}</span></span>
            </div>
          </div>
          }
        </div>
      </RightSidebar>
    </>
  );
});

export default ShoppingCart;

const CartWithItems = ({cart}) => {
  return (
      <div className="w-full ">
      <div className="pb-5">
        <CartLines cart={cart}  />
      </div>
    </div>
  )
}

export const EmptyCart = () => {
  return (
    <div className="w-full ">
      <div className="pb-5 text-center">
        Your Cart is Empty!
      </div>
    </div>
  );
}

/**
 *
 * @param cart
 * @param variant
 * @param action
 * @param icon
 * @returns {JSX.Element}
 * @constructor
 */
export const ActionButton = ({ variant, action, icon }) => {
  const [isLoading, setIsLoading] = useState();

  const onClick = (e) => {
    e.preventDefault();

    setIsLoading(true);

    if(isLoading) {
      return;
    }

    action(variant.id).finally(() => setIsLoading(false)) ;
  }

  return (
    <button className="btn btn-outline btn-circle btn-xs shadow border-base-300" onClick={onClick}>
      {!isLoading && React.createElement(icon, { size: 19 }) }
      {isLoading && <RiLoader4Fill className="icon-spin" size={22} />
      }
    </button>
);
}

/**
 * @param cartLine
 * @returns {JSX.Element}
 * @constructor
 */
export const CartImage = ({ variant }) => {
  return (
    <div className="relative w-32 h-32">
      <Image
        layout='responsive'
        className="object-contain mx-auto"
        width={100}
        height={100}
        src={variant?.image.transformedSrc ?? "https://dummyimage.com/200x200?text=No+Picture :("}
      />
    </div>
  );
}

/**
 *
 * @param cartLines
 * @returns {JSX.Element}
 * @constructor
 */
const CartLines = ({cart}) => {
  const cartLines = cart?.lines || [];

  return (
    <div className="max-h-[64vh] overflow-y-auto">
      {cartLines.map((cartLine, index) => (
        <CartLine
          key={index}
          cartLine={cartLine}
          index={index}
        />
      ))
      }
    </div>
  );
}

export const SelectedOptions = ({selectedOptions}) => {
  if (!selectedOptions) {
    return <></>;
  }

  return (
    <>
      {selectedOptions.map((option) => (
        <>
          { option.name !== 'Title'  &&
          <div className="text-xs">
            <span>{option.name}:</span>
            <span>{option.value}</span>
          </div>
          }
        </>
      ))}
    </>
  )
}

/**
 *
 * @param cart
 * @param cartLine
 * @param index
 * @returns {JSX.Element}
 * @constructor
 */
export const CartLine = ({ cartLine, index }) => {
  const { addToCart, removeFromCart, removeOneFromCart, isLoading} = useCartLogic();

  const variant = cartLine.merchandise;
  const product = variant.product;

  return (
    <ul className="menu flex-none bg-base-100 w-full">
      <li key={index} className="w-full border-b border-base-500">
        <Link href={`/products/${product.handle}`} className="text-xl min-h-[8rem]" passHref>
          <a className="">
            <div className="w-full flex justify-between">
              <div className="flex justify-start gap-2 w-full flex-2">
                <CartImage variant={variant} />
                <div className="flex flex-col">
                  <span className="hover:underline ">{product.title}</span>
                  <span className="text-sm">{currSymbol(variant.priceV2.currencyCode)}{variant.priceV2.amount}</span>
                  <div className="flex justify-end">
                    <SelectedOptions selectedOptions={variant?.selectedOptions} />
                  </div>
                </div>

              </div>
              {/*<div className="flex justify-end items-center flex-1 mr-3">*/}
              {/*  <span className="font-bold text-sm">{currSymbol(variant.priceV2.currencyCode)}{variant.priceV2.amount}</span>*/}
              {/*</div>*/}
              <div className="flex items-center flex-wrap sm:flex-nowrap justify-center gap-2 flex-1">
                <ActionButton
                  isLoading={isLoading}
                  variant={variant}
                  action={() => removeOneFromCart(variant.id, cartLine.id, cartLine.quantity - 1 )}
                  icon={FiMinus}
                />
                <span className="font-bold">{cartLine.quantity}</span>
                <ActionButton
                  isLoading={isLoading}
                  variant={variant}
                  action={addToCart}
                  icon={FiPlus}
                />
              </div>
            </div>
          </a>
        </Link>
      </li>
    </ul>
  );
}

