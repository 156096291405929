import {MENU_FIELDS} from "./fragments/MenuFields";
import {transformMenu} from "../graphql-transformers/transformers";

/***
 * @param axiosInstance
 * @param handle
 * @param cursor
 * @returns {Promise<T>}
 */
export const getMenuByHandle = async (axiosInstance, { handle }) => {
  const QUERY = `
    #graphql
    ${MENU_FIELDS}
    query ($handle: String!) {
       menu(handle: $handle) {
           ...MenuFields
      }
    },
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      handle: handle,
    }
  }).catch(e => { console.log(e); return null });

  if (response.data?.data?.menu) {
    const menu = transformMenu(response.data.data.menu);

    return menu;
  } else {
    return response.data;
  }
}