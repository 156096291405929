import {useSpring} from "@react-spring/web";
import {useEffect, useImperativeHandle} from "react";

export default function useSidebarLogic(closeSidebar, ref, side = 'left') {
  const [transition, sidebarApi] = useSpring(() => ({
    delay: 0,
    from: {
      opacity: 0,
      transform: side === 'left' ? `translateX(-100%)`: `translateX(100%)`,
    },
    to: {
      opacity: 1,
      transform: `translateX(0%)`
    },
  }));

  const [backgroundTransition, backgroundApi] = useSpring(() => ({
    delay: 0,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  }));

  useEffect(() => {
    sidebarApi.start();
    backgroundApi.start();
  }, [])

  const onCloseSidebar = () => {
    const [promise, _] = sidebarApi({ opacity: 0, transform: side === 'left' ? `translateX(-100%)`: `translateX(100%)`});
    backgroundApi({ opacity: 0});

    promise.then(closeSidebar);
  }

  useImperativeHandle(ref, () => ({
    closeSidebar() {
      onCloseSidebar();
    }
  }));

  return {
    transition,
    backgroundTransition,
    onCloseSidebar,
  }
}

