import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {AiOutlineCheckCircle, AiOutlineExclamationCircle} from "react-icons/ai";
import {classNames} from "../lib/functions";
import axios from 'axios';

export async function subscribeToNewsletter(email) {
  const response = await axios.post('/api/subscribe-to-newsletter', { email: email });

  return response.data;
}

export default function NewsLetterSignupForm() {
  const [isLoading, setIsLoading] = useState();
  const [done, setDone] = useState(false);
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);

    subscribeToNewsletter(data.email).then(() => {
      setIsLoading(false);
      reset({ email: null});
      setDone(true);
    });
  }

  return (
    <>
      <div className="w-full text-white md:text-gray-700">
        {errors.email &&
        <div className="bg-red-50 border-t border border-red-500 text-red-700 px-4 py-3 mb-2" role="alert">
          <div className="flex">
            <div className="py-1 px-1 mb-2">
              <AiOutlineExclamationCircle size={40}/>
            </div>
            <div className="flex items-center">
              <p className="text-sm">{errors.email.message}.</p>
            </div>
          </div>
        </div>
        }

        { !done &&
        <form className="flex flex-col gap-3 text-gray-700" onSubmit={handleSubmit(onSubmit)}>
          <input type="text"
                 className="w-full px-4 py-4 text-md md:text-lg border rounded-md focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600"
                 placeholder="Enter Your Email Address"
                 {...register('email', { required: true})}
          />

          <button type="submit" className={classNames(isLoading ? "loading": "", "btn btn-lg btn-base-100")}>
            <span className="text-md">Subscribe</span>
          </button>
        </form>
        }
        {done &&
          <div className="flex flex-col gap-3 text-gray-700">
            <button type="submit" className={classNames(isLoading ? "loading": "", "btn btn-lg btn-accent")}>
              <span className="pr-2"><AiOutlineCheckCircle color="#ffffff" size={30} /></span> Subscribed!
          </button>
          </div>
        }
      </div>
    </>
  );
}