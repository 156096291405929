export const PRODUCT_FIELDS = `
    #graphql
    fragment ProductFields on Product {
        id
        title
        vendor
        productType
        handle
        totalInventory
        descriptionHtml
        compareAtPriceRange {
            maxVariantPrice {
                currencyCode
                amount
            }
            minVariantPrice {
                currencyCode
                amount
            }
        }
    }
`
