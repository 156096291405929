import Meta from '../meta'
import useInitialize from "../common/Initialize.logic";
import Link from 'next/link'
import Footer from "../footer";
import useRouterScroll from "../common/useRouterScroll";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import Router from 'next/router';
import LoadingBar from "react-top-loading-bar";
import Block from "../Block";
import NavBar from "../navbar/NavBar";
import {ImBlocked} from "react-icons/im";
import {MdArrowForwardIos} from "react-icons/md";
import NewsLetterFooter from "../builder/NewsLetterFooter";

const Layout = forwardRef(({ allowed = true, preview, children, showFooter = true }, ref ) => {
  useInitialize();
  useRouterScroll();

  const LoadingBarRef = useRef();

  const startLoadingBar = () => {
    if (LoadingBarRef && LoadingBarRef.current) {
      LoadingBarRef.current.continuousStart()
    }
  }

  const completeLoadingBar = () => {
    if (LoadingBarRef && LoadingBarRef.current) {
      LoadingBarRef.current.complete()
    }
  }

  useEffect(() => {
    if (LoadingBarRef.current) {
      Router.events.on('routeChangeStart', startLoadingBar);
      Router.events.on('routeChangeComplete',  completeLoadingBar);
      Router.events.on('routeChangeError', completeLoadingBar);
    }
    return () => {
      if (LoadingBarRef.current) {
        Router.events.off('routeChangeStart', completeLoadingBar);
        Router.events.off('routeChangeComplete', completeLoadingBar);
        Router.events.off('routeChangeError', completeLoadingBar);
      }
    };
  }, [Router.events, LoadingBarRef]);

  return (
    <>
      <Meta />
      <div className="w-full bg-yellow-10">
        <LoadingBar height={6} color='#D5BC9A' ref={LoadingBarRef} />
        {allowed &&
        <div className="pt-[4rem]">
          {children}
        </div>
        }
        {!allowed &&
        <Block className="pt-[4rem]">
          <Block>
            <NavBar />
          </Block>
          <Block className="pt-[5vh] flex flex-col justify-center gap-8 items-center">
            <ImBlocked color="#D5BC9A" size={60} />

            <h1 className="text-xl font-medium title-font mb-2">Sorry! You need to login to see this page.</h1>

            <Link href={'/login'}>
              <button className={"btn btn-primary"}>
                Go to Login Page <span className="pl-2"><MdArrowForwardIos size={20}/></span>
              </button>
            </Link>
          </Block>
        </Block>
        }
        {showFooter &&
          <>
            <NewsLetterFooter/>
            <Footer/>
          </>
        }
      </div>
    </>
  )
});

export default Layout;