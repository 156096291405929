/**
 * @param state
 * @param payload
 * @returns {*&{user}}
 */
import { jwtDecode } from "./functions";
import LocalCache from "./LocalCache";

/**
 * @param state
 * @param payload
 * @returns {*}
 */
export function setUser(state, payload) {
  return {
    ...state,
    ...payload,
  };
}

/**
 * @param state
 * @param payload
 * @returns {*}
 */
export function setCart(state, payload) {
  return {
    ...state,
    ...payload,
  };
}
export function setCheckout(state, checkout) {
  const rest = {
    ...state,
    checkout: checkout
  };

  return rest;
}

/**
 * @param state
 * @param isInitialized
 * @returns {*}
 */
export function setInitialized(state, isInitialized) {
  return {
    ...state,
    initialized: isInitialized,
  };
}

export function logout(setUser, setCart) {
  setUser({user: null});
  setCart({cart: null});

  setInitialized(false);

  localStorage.removeItem('token');
  localStorage.removeItem('customerAccessToken');
}

export function login(setUserCb, token) {
  const user = jwtDecode(token);

  setUserCb({ user: user });

  localStorage.setItem('token', token)
}