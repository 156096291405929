import axios from "axios";

const axiosAdminServerSideInstance = axios.create({
  baseURL: process.env.SHOPIFY_BASE_URL,
});

// Set the AUTH token for any request
axiosAdminServerSideInstance.interceptors.request.use(config => {
  config.headers['X-Shopify-Access-Token'] = process.env.SHOPIFY_API_KEY;

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosAdminServerSideInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axiosAdminServerSideInstance;

