import {classNames} from "../lib/functions";

export default function Footer(props) {
  const { backgroundColor = "#0C0C0C", fontColor = '#fff', additionalClassNames = "" } = props;

  const style = {
    ...(backgroundColor && {backgroundColor: backgroundColor}),
    ...(fontColor && {color: fontColor}),
  }

  return (
    <footer style={style} className={classNames("footer px-20 md:px-32 py-20", additionalClassNames)}>
      <div>
        <span className="footer-title">Services</span>
        <a className="link link-hover">Branding</a>
        <a className="link link-hover">Design</a>
        <a className="link link-hover">Marketing</a>
        <a className="link link-hover">Advertisement</a>
      </div>
      <div>
        <span className="footer-title">Company</span>
        <a className="link link-hover">About us</a>
        <a className="link link-hover">Contact</a>
        <a className="link link-hover">Jobs</a>
        <a className="link link-hover">Press kit</a>
      </div>
      <div>
        <span className="footer-title">Legal</span>
        <a className="link link-hover">Terms of use</a>
        <a className="link link-hover">Privacy policy</a>
        <a className="link link-hover">Cookie policy</a>
      </div>
    </footer>
  );
};