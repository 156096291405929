import React, {forwardRef} from "react";
import {createPortal} from 'react-dom';
import {animated} from "@react-spring/web";
import OutsideClickHandler from 'react-outside-click-handler';
import useSidebarLogic from "./Sidebar.logic";

/**
 * @param children
 * @param title
 * @param isOpen
 * @returns {|null}
 * @constructor
 */
const LeftSidebar = forwardRef(({ children, isOpen = false, closeSidebar}, ref ) => {
  if (!isOpen) {
    return null
  }

  const { transition, onCloseSidebar, backgroundTransition }  = useSidebarLogic(closeSidebar, ref);

  return createPortal(
    <div className="sidebar top-0 left-0 fixed w-full h-full z-50">
      <animated.div style={{ zIndex: 1, ...backgroundTransition }}>
        <div className="modal-background absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50" />
      </animated.div>

      <animated.div style={{ zIndex: 10, ...transition }}>
          <div className="sidebar-menu relative max-w-screen-xl min-h-screen bg-white inset-0 sm:max-w-[30rem] max-w-[85vw]">
            <OutsideClickHandler onOutsideClick={onCloseSidebar}>
            {children}
            </OutsideClickHandler>
          </div>
      </animated.div>,
    </div>,
    document.getElementById('__next'))
});

export default LeftSidebar;