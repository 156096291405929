import {PRODUCT_VARIANT_FIELDS} from "./ProductVariantFields";

export const CART_FIELDS = `
    #graphql
    ${PRODUCT_VARIANT_FIELDS}
    fragment CartFields on Cart {
        id
        createdAt
        updatedAt
        totalQuantity
        lines(first:20) {
            edges {
                node {
                    id
                    quantity
                    merchandise {
                        ...ProductVariantFields
                    }
                }
            }
        }
        attributes {
            key
            value
        }
        estimatedCost {
            totalAmount {
                amount
                currencyCode
            }
            subtotalAmount {
                amount
                currencyCode
            }
            totalTaxAmount {
                amount
                currencyCode
            }
            totalDutyAmount {
                amount
                currencyCode
            }
        }
        buyerIdentity {
            email
            phone
            customer {
                id
            }
            countryCode
        }
    }
`
