import Block from "../Block";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {classNames} from "../../lib/functions";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {subscribeToNewsletter} from "../NewsLetterSignupForm";

export default function NewsLetterFooter(props) {
  const {
    backgroundColor = "#FDFAF8",
    title = 'Join the Family',
    subtitle = "Stay up to date and get the best deals, subscribe to our newsletter!",
  } = props;

  const [isLoading, setIsLoading] = useState();
  const [done, setDone] = useState(false);
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm();

  const style = {
    ...(backgroundColor && {backgroundColor: backgroundColor}),
  }

  const onSubmit = (data) => {
    setIsLoading(true);

    subscribeToNewsletter(data.email).then(() => {
      setIsLoading(false);
      reset({ email: null});
      setDone(true);
    });
  }
  return (
    <Block style={style} className="md:px-32 px-10 py-8">
      <Block className="max-w-2xl">
        <Block className="text-center mx-auto">
          <h1 className="text-2xl py-2 font-mazzard font-bold">{title}</h1>
          <p className="font-mazzard text-gray-500 py-2">{ subtitle && subtitle }</p>
        </Block>
        { !done &&
          <form className="flex justify-center flex-wrap gap-3 py-8 text-gray-700" onSubmit={handleSubmit(onSubmit)}>
            <input type="text"
                   className="w-full border-b-2 border-b-gray-400 bg-transparent py-4 px-4 text-md md:text-lg"
                   placeholder="Enter Your Email Address"
                   {...register('email', { required: true})}
            />

            <button type="submit" className={classNames(isLoading ? "loading": "", "btn no-animation obg-transparent btn-outline")}>
              <span className="text-md">Submit</span>
            </button>
          </form>
        }
        {done &&
          <div className="flex flex-col gap-3 text-gray-700">
            <button type="submit" className={classNames(isLoading ? "loading": "", "btn bg-transparent btn-outline")}>
              <span className="pr-2"><AiOutlineCheckCircle color="#ffffff" size={30} /></span> Subscribed!
            </button>
          </div>
        }
      </Block>
    </Block>
  );
}