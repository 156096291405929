import Link from 'next/link'
import NavLink from "../nav-link";
import {useStateMachine} from "little-state-machine";
import {logout, setCart, setUser} from "../../lib/actions";
import {useRouter} from "next/router";
import ShoppingCart from "./ShoppingCart";
import {MdOutlinePersonOutline} from "react-icons/md";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import LeftSidebar from "../common/LeftSidebar";
import {AiOutlineClose, AiOutlineMenu} from "react-icons/ai";
import {BsDot} from "react-icons/bs";
import Search from "./Search";
import {classNames} from "../../lib/functions";
import {isEmpty} from "lodash";
import CollapseMenuItem from "../common/CollapseMenuItem";
import {getMenuByHandle} from "../../lib/resources/menu";
import axiosStorefrontInstance from "../../lib/axios-instances/axios-storefront-instance";

const topMenu = [
  {
    text: 'Home',
    href: '/',
    icon: <BsDot />
  },
  {
    text: 'Sustainability',
    href: '/sustainability',
    icon: <BsDot />
  },
  {
    text: 'Get Inspired',
    href: '/get-inspired',
    icon: <BsDot />
  },
  {
    text: 'All Collections',
    href: '/collections',
    icon: <BsDot />
  },
  {
    text: 'Brands',
    href: '/brands',
    icon: <BsDot />
  },
  {
    text: 'Our story',
    href: '/our-story',
    icon: <BsDot />
  },
  {
    text: 'Blog',
    href: '/blog',
    icon: <BsDot />
  },
  {
    text: 'Contact',
    href: '/contact',
    icon: <BsDot />
  },
  {
    text: 'Faq',
    href: '/faq',
    icon: <BsDot />
  },
];

const NavBar = forwardRef(({ menu = [] }, ref ) => {
  const router = useRouter();
  const ShoppingCartRef = useRef();
  const SidebarRef = useRef();

  const [mainMenu, setMainMenu] = useState(menu);

  useEffect(() => {
    if (isEmpty(mainMenu)) {
      getMenuByHandle(axiosStorefrontInstance, {handle: 'main'}).then((menu) => {
        setMainMenu(menu);
      })
    }
  }, [mainMenu])

  const { actions, state } = useStateMachine({
    setUser, setCart
  });

  const logoutAction = () => {
    logout(actions.setUser, actions.setCart);
    router.replace('/');
  }

  useImperativeHandle(ref, () => ({
    openCart() {
      ShoppingCartRef.current.openCart();
    }
  }));

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    if (!sidebarOpen) {
      setSidebarOpen(true);
    } else {
      SidebarRef.current.closeSidebar()
    }
  }

  return (
    <div className="navbar font-mazzard bg-base-100 shadow-xl fixed top-0 left-0 z-20">
      <div className="navbar-start">
        <button className="btn btn-outline border-0" onClick={toggleSidebar}>
          <AiOutlineMenu size={20} />
        </button>
      </div>
      <div className="navbar-center">
        <Link href={`/`}>
          <a className="btn btn-ghost normal-case text-xl">LENTO</a>
        </Link>
      </div>

      <div className="navbar-end">
        { state.user?.firstName &&
        <>
          <div className="dropdown dropdown-end">
            <a tabIndex="0" className="cursor-pointer hover:text-cyan duration-200 transition-colors">
              <div className="flex">
                <div className="hidden sm:flex flex-col text-sm">
                  <span>Welcome</span>
                  <span className="font-bold">{state.user.firstName} {state.user.lastName}</span>
                </div>
                <button className="btn btn-ghost btn-circle">
                  <MdOutlinePersonOutline size={22} />
                </button>
              </div>
            </a>
            <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
              <li>
                <Link href="/account">
                  <a>Account settings</a>
                </Link>
              </li>
              <li onClick={logoutAction}>
                <a className="hover:underline cursor-pointer hover:text-cyan duration-200 transition-colors">Logout</a>
              </li>
            </ul>
          </div>
        </>
        }
        { !state.user?.firstName &&
        <div className="dropdown dropdown-end">
          <a tabIndex="0" className="cursor-pointer hover:text-cyan duration-200 transition-colors">
            <div className="flex">
              <button className="btn btn-ghost btn-circle">
                <MdOutlinePersonOutline size={22} />
              </button>
            </div>
          </a>
          <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            <li>
              <Link href={'/login'}>
              <a className="hover:underline cursor-pointer hover:text-cyan duration-200 transition-colors">Login</a>
              </Link>
            </li>
          </ul>
        </div>
        }
        <Search />
        {/*<Notifications />*/}
        <ShoppingCart ref={ShoppingCartRef} />
      </div>
      <LeftSidebar ref={SidebarRef} isOpen={sidebarOpen} closeSidebar={() => { setSidebarOpen(false)}}  >
        <div className="flex justify-between items-center px-3 py-2">
          <span className="normal-case font-bold text-xl">LENTO</span>
          <button onClick={toggleSidebar} className="btn btn-outline border-0">
            <AiOutlineClose size={25}/>
          </button>
        </div>
        <div className="collapsable-menu font-mazzard">
          { mainMenu.map((menuItem, index) => (
            <>
              {isEmpty(menuItem.items) &&
              <li className={classNames(menuItem.pathname === router.pathname ? "bordered bg-lento-almond-light": "")} key={'li' + index} >
                <NavLink
                  key={index}
                  href={menuItem.pathname}
                  text={menuItem.title}
                  icon={<BsDot/>}
                />
              </li>
              }
              {!isEmpty(menuItem.items) &&
              <li>
                <CollapseMenuItem
                  defaultOpen={true}
                  key={index}
                  menuItem={menuItem}
                />
              </li>
              }
            </>
          ))}
        </div>
      </LeftSidebar>
    </div>
  )
});

// <div className={classNames(menuItem.pathname === router.pathname ? "bordered bg-lento-almond-light": "")} key={'li' + index}>
//   <NavLink
//     key={index}
//     href={menuItem.pathname}
//     text={menuItem.title}
//     icon={<BsDot/>}
//     // icon={menuItem.icon}
//   >
//     <>
//       {!isEmpty(menuItem.items) && menuItem.items.map((menuItem, index) => (
//         <ul className="submenu">
//           <li
//             className={classNames(menuItem.pathname === router.pathname ? "bordered" : "", "px-5")}
//             key={'li-sub' + index}>
//             <NavLink
//               key={index}
//               href={menuItem.pathname}
//               text={menuItem.title}
//               icon={<BsDot/>}
//               // icon={menuItem.icon}
//             />
//           </li>
//         </ul>
//       ))
//       }
//     </>
//   </NavLink>
export default NavBar;