import {PRODUCT_VARIANT_FIELDS} from "./fragments/ProductVariantFields";
import {PRODUCT_FIELDS} from "./fragments/ProductFields";
import {PRODUCT_IMAGE_FIELDS} from "./fragments/ProductImageFields";
import {transformOrders, transformProducts, transformProductsV2} from "../graphql-transformers/transformers";

/***
 * @param axiosInstance
 * @param handle
 * @param cursor
 * @returns {Promise<T>}
 */
export const getProductByHandle = async (axiosInstance, { handle }) => {
  const QUERY = `
    #graphql
    ${PRODUCT_FIELDS}
    ${PRODUCT_VARIANT_FIELDS}
    ${PRODUCT_IMAGE_FIELDS}
    query ($handle: String!) {
       productByHandle(handle: $handle) {
           ...ProductFields
           images(first:20) {
               edges {
                   node {
                       ...ProductImageFields
                   }
               }
           }
           careGuide: metafield(namespace: "descriptors", key: "care_guide") {
              value
              type
          }
          variants(first: 20) {
              edges {
                  cursor
                  node {
                      ...ProductVariantFields
                  }
              }
          }
      }
    },
  `;

  return axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      handle: handle,
    }
  }).catch(e => { console.log(e); return null });
}

/**
 *
 * @param axiosInstance
 * @param productId
 * @returns {Promise<T>}
 */
export const getProductRecommendations = async (axiosInstance, { productId }) => {
  const QUERY = `
      #graphql
      ${PRODUCT_FIELDS}
      ${PRODUCT_VARIANT_FIELDS}
      ${PRODUCT_IMAGE_FIELDS}
      query ($productId: ID!){
          productRecommendations(productId: $productId) {
              ...ProductFields
              images(first:1) {
                  edges {
                      node {
                          ...ProductImageFields
                      }
                  }
              }
              variants(first: 1) {
                  edges {
                      cursor
                      node {
                          ...ProductVariantFields
                      }
                  }
              }
          }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      productId: productId,
    }
  }).catch(e => { console.log(e); return null });

  if (response.data?.data?.productRecommendations) {
    return transformProductsV2(response.data.data.productRecommendations);
  } else {
    return response.data;
  }
};

export const getProductsById = async (axiosInstance, { ids }) => {
  const QUERY = `
      #graphql
      ${PRODUCT_FIELDS}
      ${PRODUCT_VARIANT_FIELDS}
      ${PRODUCT_IMAGE_FIELDS}
      query ($ids: [ID!]!){
          nodes(ids: $ids) {
              ...on Product {
                  ...ProductFields
                  images(first:1) {
                      edges {
                          node {
                              ...ProductImageFields
                          }
                      }
                  }
                  variants(first: 1) {
                      edges {
                          cursor
                          node {
                              ...ProductVariantFields
                          }
                      }
                  }
              }
          }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      ids: ids,
    }
  }).catch(e => { console.log(e); return null });

  if (response.data?.data?.nodes) {
    return transformProductsV2(response.data.data.nodes);
  } else {
    return response.data;
  }
}