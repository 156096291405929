export const PRODUCT_VARIANT_FIELDS = `
    #graphql
    fragment ProductVariantFields on ProductVariant {
        id
        title
        availableForSale
        selectedOptions {
            name
            value
        }
        image {
            id
            url
            altText
            width
            height
            transformedSrc
        }
        priceV2 {
            currencyCode
            amount
        }
        compareAtPriceV2 {
            currencyCode
            amount
        }
        product {
            id
            title
            handle
        }
    }
`
