import {isEmpty} from "lodash";
import LocalCache from "./LocalCache";

const RecentlyViewedList = {
  max: 20,
  list: [],

  getList(excludeItem = null) {
    if (excludeItem) {
      return this.list.filter((item) => {
        return item !== excludeItem;
      });
    } else {
      return this.list;
    }
  },

  setList(list) {
    this.list = [...list];
  },

  push(item) {
    if (this.list.indexOf(item) < 0) {
      const newList = [...this.list];
      newList.push(item);
      this.list = newList;

      LocalCache.set('recently_viewed', this.list, 2);
    }

    if (this.list.length > this.max) {
      this.pop();
    }
  },

  pop() {
    if (!isEmpty(this.list)) {
      const newList = [...this.list];

      newList.pop();

      this.list = newList;
    }
  }
}

export default RecentlyViewedList;