import {useStateMachine} from "little-state-machine";
import {setCart} from "../../lib/actions";
import * as cartApi from "../../lib/resources/cart";
import {useState} from "react";
import axiosStorefrontInstance from "../../lib/axios-instances/axios-storefront-instance";

export default function useCartLogic() {
  const [isLoading, setIsLoading] = useState();

  const { actions, state } = useStateMachine({
    setCart
  });

  const addOrCreateCart = (cart, variantId) => {
    if (!cart) {
      // remove any lingering old checkoutId
      localStorage.removeItem('checkoutId');

      return cartApi.createCart(axiosStorefrontInstance, { variantId: variantId });
    } else {
      return cartApi.addToCart(axiosStorefrontInstance, { cartId: cart.id, variantId: variantId } );
    }
  }

  const addToCart = (variantId) => {
    setIsLoading(true);

    const cart = state.cart || null;
    const varId = variantId;

    return addOrCreateCart(cart, varId).then((cart) => {
      localStorage.setItem('cartId', cart.id);
      actions.setCart({cart});
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const removeOneFromCart = (variantId, cartLineId, quantity) => {
    setIsLoading(true);
    const cart = state.cart;

    const newQuantity = quantity < 0 ? 0: quantity;

    if (newQuantity === 0) {
      return cartApi.removeCartLine(axiosStorefrontInstance, { cartId: cart.id, cartLineId: cartLineId}).then((data) => {
        actions.setCart({cart: data});
      }).finally(() => {
        setIsLoading(false);
      });
    }
    // return cartApi.r(axiosStorefrontInstance, { cartId: cart.id, variantId: variantId, cartLineId: cartLineId, quantity: newQuantity}).then((data) => {
    //   actions.setCart({cart: data});
    // }).finally(() => {
    //   setIsLoading(false);
    // });

    return cartApi.updateCartLine(axiosStorefrontInstance, { cartId: cart.id, variantId: variantId, cartLineId: cartLineId, quantity: newQuantity}).then((data) => {
      actions.setCart({cart: data});
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return {
    addToCart,
    removeOneFromCart,
    isLoading,
    setIsLoading,
  }
}
