import {unpackEdges} from "../functions";
import products from "../../pages/api/products";

/**
 * @returns {*|null}
 * @param collection
 */
export default function transformCollection(collection) {
  if (collection.products) {

    if (collection.products?.pageInfo) {
      collection.pageInfo = collection.products?.pageInfo;
    }

    if (collection.products?.filters) {
      collection.filters = collection.products?.filters;
    }

    collection.products = unpackEdges(collection, 'products');

    collection.products = collection.products.map((product) => {
      product.variants = unpackEdges(product, 'variants');
      product.images = unpackEdges(product, 'images');

      return product;
    });
  }

  return collection;
}

export function transformOrder(data) {
  data.lineItems = unpackEdges(data, 'lineItems');

  return data;
};

/**
 * @returns {*|null}
 * @param data
 */
export function transformOrders(data) {
  data.orders = unpackEdges(data, 'orders');

  data.orders = data.orders.map((order) => {
    order.lineItems = unpackEdges(order, 'lineItems');

    return order;
  });

  return data;
}

/**
 * @returns {*|null}
 * @param data
 */
export function transformCollections(data) {
  data.collections = unpackEdges(data, 'collections');

  return data;
}

export function transformBlog(blog) {
  blog.articles = unpackEdges(blog, 'articles');

  return blog;
}

/**
 *
 * @returns {*|null}
 */
export function transformProducts(data, key = 'products') {
  data.products = unpackEdges(data, key);

  data.products = data.products.map((product) => {
    product.images = unpackEdges(product, 'images');
    product.variants = unpackEdges(product, 'variants');

    return product;
  });

  return data;
}

/**
 *
 * @returns {*|null}
 */
export function transformProductsV2(products) {
  return products.map((product) => {
    product.images = unpackEdges(product, 'images');
    product.variants = unpackEdges(product, 'variants');

    return product;
  });
}

export function transformMenu(data)
{
  return data.items.map((item) => {
    item.pathname = new URL(item.url).pathname;

    item.items = item.items.map((subItem) => {
      subItem.pathname = new URL(subItem.url).pathname;

      return subItem;
    })

    return item;
  })
}