import axios from 'axios';
import transformCart from "./graphql-transformers/transform-cart";
import {isEmpty} from "lodash";
import {transformOrders, transformProducts} from "./graphql-transformers/transformers";
import {PRODUCT_FIELDS} from "./resources/fragments/ProductFields";
import {PRODUCT_VARIANT_FIELDS} from "./resources/fragments/ProductVariantFields";
import {PRODUCT_IMAGE_FIELDS} from "./resources/fragments/ProductImageFields";

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SHOPIFY_BASE_URL,
});

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers['X-Shopify-Storefront-Access-Token'] = token;
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

/**
 *
 * @param userData
 * @returns {Promise<void>}
 */
export async function signupCustomer(userData) {
  const response = await axiosInstance.post('/customers/signup', userData);

  return response.data;
}

export async function getCustomerAndOrders(customerAccessToken) {

};

/**
 * @param checkoutId
 * @param customerAccessToken
 * @returns {Promise<null|*>}
 */
export async function associateCustomerToCheckout(checkoutId, customerAccessToken) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query: `mutation checkoutCustomerAssociateV2($id: ID!, $customerAccessToken: String!) {
      checkoutCustomerAssociateV2(checkoutId: $id, customerAccessToken: $customerAccessToken) {
        checkout {
          id
          webUrl
        }
        customer {
          defaultAddress {
            id
            address1
            city
            country
            company
            firstName
            lastName
            formatted
            phone
            zip
          }
        }
        userErrors {
          field
          message
        }
      }
    }`,
    variables: {
      id: checkoutId,
      customerAccessToken: customerAccessToken,
    }
  }).catch(e => { console.log(e.response.data); return null});

  if (response.data?.data?.checkoutCustomerAssociateV2?.checkout) {
    return response.data.data.checkoutCustomerAssociateV2?.checkout;
  } else {
    return null;
  }
};

/**
 * @param customerAccessToken
 * @param addressData
 * @param addressId
 * @returns {Promise<null|*>}
 */
export async function updateCustomerAddress(customerAccessToken, addressData, addressId) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query: `mutation customerAddressUpdate($address: MailingAddressInput!, $customerAccessToken: String!, $id: ID!) {
      customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
        customerAddress {
          id
          address1
          city
          country
          company
          firstName
          lastName
          formatted
          phone
          zip
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }`,
    variables: {
      address: addressData,
      customerAccessToken: customerAccessToken,
      id: addressId,
    }
  });

  if (response.data?.data?.customerAddressUpdate) {
    return response.data.data.customerAddressUpdate;
  } else {
    return null;
  }
}

/**
 *
 * @param customerAccessToken
 * @param addressData
 * @returns {Promise<any>}
 */
export async function createCustomerAddress(customerAccessToken, addressData) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query: `mutation customerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) {
      customerAddressCreate(address: $address, customerAccessToken: $customerAccessToken) {
        customerAddress {
          id
          address1
          city
          country
          company
          firstName
          lastName
          formatted
          phone
          zip
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }`,
    variables: {
      address: addressData,
      //   address1: "",
      //   address2: "",
      //   city: "",
      //   company: "",
      //   country: "",
      //   firstName: "",
      //   lastName: "",
      //   phone: "",
      //   province: "",
      //   zip: ""
      customerAccessToken: customerAccessToken,
    }
  });

  if (response.data?.data?.customerAddressCreate) {
    return response.data.data.customerAddressCreate;
  } else {
    return null;
  }
}

/**
 * @returns {Promise<null|*>}
 */
export async function findCustomerByAccessToken(customerAccessToken) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query:`query getCustomer($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          id
          firstName
          lastName
          acceptsMarketing
          email
          phone
          defaultAddress {
            id
            address1
            city
            country
            company
            firstName
            lastName
            formatted
            phone
            zip
          }
          addresses(first: 10) {
            edges {
              node {
                address1
              }
            }
          }
        }
    }`,
    variables: {
      customerAccessToken: customerAccessToken,
    }
  }).catch(e => { console.log(e.response.data); return null});

  if (response.data?.data?.customer) {
    return response.data.data.customer;
  } else {
    return null;
  }
}

/**
 * @param customerAccessToken
 * @returns {Promise<null|*>}
 */
export async function findCustomer(customerAccessToken) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query:`query getCustomer($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          id
          firstName
          lastName
          acceptsMarketing
          email
          phone
        }
    }`,
    variables: {
      customerAccessToken: customerAccessToken,
    }
  }).catch(e => { console.log(e.response.data); return null});

  if (response.data?.data?.customer) {
    return response.data.data.customer;
  } else {
    return null;
  }
}

/**
 *
 * @param userData
 * @returns {Promise<void>}
 */
export async function loginCustomer(userData) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
      customerAccessTokenCreate(input: $input) {
        customerUserErrors {
          code
          field
          message
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
      }
    }`,
    variables: {
      input: {
        email: userData.email,
        password: userData.password,
      }
    }
  }).catch(e => { console.log(e.response.data); return null});

  if (response.data?.data?.customerAccessTokenCreate) {
    return response.data.data.customerAccessTokenCreate;
  } else {
    return null;
  }
}

/**
 *
 * @param handle
 * @returns {Promise<any>}
 */
export async function getProductByHandle(handle) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query: `query productByHandle(handle: $handle) {
        id
        handle
        title
        tags
        productType
        vendor
    }`,
    variables: {
      handle: handle,
    }
  });

  return response.data;
}

export async function getProduct(productId) {
  const response = await axiosInstance.post('/api/2022-04/graphql.json', {
    query:`query getProduct($cartInput: CartInput) {
      {
        product(id: $id) {
          title
        }
      }`,
    variables: {
      id: productId,
    }
  });

  return response.data;
}

/***
 * @returns {Promise<T>}
 */
export const getShop = async () => {
  const QUERY = `
      {
        shop {
          shippingPolicy {
            body
            title
          }
        }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
  }).catch(e => console.log(e));

  if (response.data?.data?.shop) {
    return response.data.data.shop;
  } else {
    return null;
  }
}

/**
 * @param query
 * @param limit
 * @returns {Promise<null|*|null>}
 */
export const searchProducts = async ({query, limit = 10}) => {
  const QUERY = `
      #graphql
      ${PRODUCT_FIELDS}
      ${PRODUCT_VARIANT_FIELDS}
      ${PRODUCT_IMAGE_FIELDS}
      query SearchProducts(
          $query: String!
          $limit: Int!) {
          products(first: $limit, query: $query) {
              edges {
                  node {
                      ...ProductFields
                      images(first:1) {
                          edges {
                              node {
                                  ...ProductImageFields
                              }
                          }
                      }
                      variants(first:1) {
                          edges {
                              node {
                                  ...ProductVariantFields
                              }
                          }
                      }
                  }
              }
          }
      }`;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      query: query,
      limit: limit,
    }
  }).catch(e => console.log(e));

  if (response.data?.data?.products) {
    return transformProducts(response.data.data);
  } else {
    return null;
  }
}

