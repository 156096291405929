const LocalCache = {
  set(key, value, ttlDays = 1) {
    const now = new Date()

    const ttl = ttlDays * 86400000;

    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }

    localStorage.setItem(key, JSON.stringify(item));
  },
  get(key) {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)

      return null
    }

    return item.value
  }
}

export default LocalCache;