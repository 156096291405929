import {useStateMachine} from "little-state-machine";
import React, {useRef, useState} from "react";
import {AiOutlineClose, AiOutlineSearch} from "react-icons/ai";
import RightSidebar from "../common/RightSidebar";
import {debounce, isEmpty} from "lodash";
import * as shopifyClientApi from "../../lib/api-client-side-shopify";
import Image from "next/image";
import {currSymbol} from "../../lib/currencies";
import {RiLoader4Fill} from "react-icons/ri";
import {useRouter} from "next/router";
import LeftSidebar from "../common/LeftSidebar";

export default function Search() {
  const { state: { cart } } = useStateMachine();
  const [foundProducts, setFoundProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const SidebarRef = useRef();

  const toggleSidebar = () => {
    if (!sidebarOpen) {
      setSidebarOpen(true);
    } else {
      SidebarRef.current.closeSidebar()
    }
  }

  const doSearch = (e) => {
    const query = e.target.value;

    if (!query) {
      setFoundProducts([])
    } else {
      setIsLoading(true)

      shopifyClientApi.searchProducts({query: query}).then((data) => {
        if (!isEmpty(data?.products)) {
          setFoundProducts(data.products)
        } else {
          setFoundProducts(null)
        }
      }).then(() => setIsLoading(false))
    }
  }

  const SearchDebounced = debounce(doSearch, 400);

  const router = useRouter();

  const onClickProduct = (href) => {
    const routeParams = {
      pathname: href,
    };


    router.push(routeParams, undefined, {scroll: true})
      .then(() => setSidebarOpen(false));
  }

  return (
    <>
      <div className="indicator">
        <button onClick={() => setSidebarOpen(true)} className="btn btn-ghost btn-circle">
          <AiOutlineSearch size={25} />
        </button>
      </div>
      <RightSidebar ref={SidebarRef} isOpen={sidebarOpen} closeSidebar={() => setSidebarOpen(false)} >
        <div className="flex justify-end items-center px-3 py-2">
          <button onClick={toggleSidebar} className="btn btn-outline border-0">
            <AiOutlineClose size={25} />
          </button>
        </div>
        <div>
          <div className="px-4">
            <div className="flex justify-center">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Search our Products</span>
                  <span className="label-text-alt">
                  <AiOutlineSearch size={25} />
                </span>
                </label>
                <input onChange={SearchDebounced} type="text" placeholder="Type here" className="input input-bordered w-full"/>
              </div>
            </div>
          </div>
          <div className="pt-2">
            <div className="w-full min-h-9 py-1 flex justify-center">
              {isLoading &&
              <RiLoader4Fill className="icon-spin" size={38}/>
              }
            </div>
            {!foundProducts &&
            <div className="pt-10 w-full flex items-center flex-col">
              <span>No results found ...</span>
            </div>
            }
            {foundProducts &&
              <div className="max-h-[80%]">
                <ul className="menu bg-base-100 h-full overflow-y-auto w-full">
                  {foundProducts.map((product, index) => (
                    <li key={index} className="w-full border-b border-base-500">
                      <div onClick={() => onClickProduct(`/products/${product.handle}`)} className="text-xl min-h-[8rem]">
                        <a className="flex">
                          <div className="w-full flex justify-between items-center">
                            <div className="flex justify-start gap-2 w-full flex-2">
                              <div className="relative w-40 h-40">
                                <Image
                                  layout='responsive'
                                  className="object-contain mx-auto"
                                  width={100}
                                  height={100}
                                  src={product.images?.[0].transformedSrc ?? "https://dummyimage.com/200x200?text=No+Picture :("}
                                />
                              </div>
                              <div className="flex flex-col text-xl">
                                <span className="hover:underline ">{product.title}</span>
                                <span
                                  className="text-sm">{currSymbol(product.variants?.[0].priceV2.currencyCode)}{product.variants?.[0].priceV2.amount}</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            }
          </div>
        </div>
      </RightSidebar>
    </>
  );
}
